import { Dialog } from './ui/dialog';
import { LoadingSpinner } from './ui/spinner';

interface ILoadingDialogProps {
  open: boolean;
  label?: string;
}

export const LoadingDialog = ({ open, label }: ILoadingDialogProps) => (
  <Dialog open={open}>
    <Dialog.Content className="flex w-full flex-col items-center justify-center border-none bg-transparent shadow-none outline-none">
      <LoadingSpinner className="text-white" size={50} />
      {label ? <p className="mt-4 text-white">{label}</p> : null}
    </Dialog.Content>
  </Dialog>
);
